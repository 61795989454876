.coming-soon-page {
    background-color: var(--primary-blue);
    color: var(--primary-white);
    height: 100vh; // Full viewport height
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', sans-serif;
    margin: 0 auto; /* Center the container */


    .logo {
        img {
            width: 500px; // Adjust as needed
            margin-bottom: -100px;
            margin-top: -200px;
            max-width: 100%;
            height: auto;
        }
    }

    p {
        font-size: 1.5em;
        font-weight: bold;
    }
}

// body {
//     margin: 0; /* Remove default margin */
//   }